const ProgressBarData01 = [
    {
        title:"WEB INTERACTIVE",
        value:"80",
    },
    {
        title:"LOGO AND IDENTITY",
        value:"95",
    },
    {
        title:"GRAPHIC DESIGN",
        value:"75",
    },
]

const ProgressBarData02 = [
    {
        title:"DEVELOPMENT",
        value:"95",
    },
    {
        title:"PROMOTION",
        value:"85",
    },
    {
        title:"BRANDING",
        value:"75",
    },
]

export { ProgressBarData01 , ProgressBarData02}